import React from 'react';

export default function MicIcon() {
  return (
    <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.00014 1C6.31815 1 5.6641 1.28734 5.18186 1.7988C4.69963 2.31026 4.42871 3.00396 4.42871 3.72727V11C4.42871 11.7233 4.69963 12.417 5.18186 12.9285C5.6641 13.4399 6.31815 13.7273 7.00014 13.7273C7.68212 13.7273 8.33618 13.4399 8.81841 12.9285C9.30065 12.417 9.57157 11.7233 9.57157 11V3.72727C9.57157 3.00396 9.30065 2.31026 8.81841 1.7988C8.33618 1.28734 7.68212 1 7.00014 1V1Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 9.18213V11.0003C13 12.6881 12.3679 14.3067 11.2426 15.5001C10.1174 16.6935 8.5913 17.364 7 17.364C5.4087 17.364 3.88258 16.6935 2.75736 15.5001C1.63214 14.3067 1 12.6881 1 11.0003V9.18213"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.99951 17.3633V20.9996"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M3.57178 21H10.4289" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}
