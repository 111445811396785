import React, { useEffect, useState } from 'react';
import { ObjectID } from 'bson';

import {
  DialogContent,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  Button,
  Theme,
  DialogTitle,
  TextField,
  TextareaAutosize,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AudioVideoTabs from '../../custom/AudioVideoTabs/AudioVideoTabs';
import InputBox from '../Input/InputBox';
import { PhotoCamera } from '@material-ui/icons';
import ImageInput from '../ImageInput/ImageInput';
import CloseIcon from '../../icons/CloseIcon';
import axios from 'axios';
import { UPLOAD_SERVER } from '../../data-service/config';
import Snackbar from '../../components/Snackbar/Snackbar';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '600px',
    minHeight: '350px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 32px)',
    },
    '& .inputSelect': {
      width: 'calc(100% - 35px)',
    },
  },
  button: {
    float: 'right',
  },
  paper: {
    [theme.breakpoints.down('xs')]: {
      margin: '16px',
    },
  },
  headline: {
    marginBottom: '1.3em',
    fontSize: '1.1rem',
  },
  listSection: {
    margin: '2em 0 0.8em',
    '&:first-child': {
      margin: '1em 0 2em 0',
    },
  },
  joinBtn: {
    height: '44px',
    width: '137px',
    backgroundColor: '#cd8764',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#e8986f',
    },
  },
  input: {
    display: 'none',
  },
}));

const reportProblem = { issue: '', comment: '' };

const ReportProblemDialog = ({
  open,
  onClose,
  sessionDetails,
}: {
  open: boolean;
  onClose: () => void;
  sessionDetails: any;
}) => {
  const classes = useStyles();
  const [image, setImage] = useState<any>('');
  const [src, setSrc] = useState<any>('');

  const [reportIssue, setreportIssue] = useState<any>(reportProblem);
  const [isMentor, setIsMentor] = useState(localStorage.getItem('mentor'));
  const [screenLinks, setscreenLinks] = useState<any>([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setImage('');
    setSrc('');
  }, []);

  const handleReportChange = (e: any) => {
    e.preventDefault();
    setreportIssue({ ...reportIssue, [e.target.name]: e.target.value });
  };

  const handleReportIssueSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    var user_id = isMentor ? sessionDetails?.mentorId : sessionDetails?.candidateId?._id;
    formData.append('user_id', user_id);
    formData.append('image', image);

    axios
      .post(`${UPLOAD_SERVER}/upload-image`, formData)
      .then(res => {
        setscreenLinks([...screenLinks, res.data.image[0]]);
        const id = new ObjectID();
        const reportData = {
          meetingId: sessionDetails._id ? sessionDetails?._id : id.toString(),
          reporterEmail: sessionDetails._id
            ? isMentor
              ? sessionDetails?.attendees?.mentorEmail
              : sessionDetails?.attendees?.candidateEmail
            : 'personal@mapout.com',
          mentorId: sessionDetails._id ? sessionDetails?.mentorId : '0987654321',
          issue: reportIssue.issue,
          comments: reportIssue.comment,
          linktoscrnsht: screenLinks,
          start: sessionDetails._id ? sessionDetails?.start?.dateTime : 'no',
          end: sessionDetails._id ? sessionDetails?.end?.dateTime : 'no',
        };

        axios
          .post(`${process.env.REACT_APP_MAPOUT_BACKEND_URL}/sessions/reportanissue`, reportData)
          .then(res => {
            setOpenSnackBar(true);
            onClose();
            setLoading(false);
            setTimeout(() => setOpenSnackBar(false), 3000);
          })
          .catch(err => {
            setLoading(false);
            console.log('Error on reporting issue', err);
          });
      })
      .catch(err => {
        console.log('err : ', err);
      });
  };

  const handleScreenChange = (e: any) => {
    e.preventDefault();
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      //@ts-ignore
      setSrc(fileReader.result);
    };
    setImage(e.target.files[0]);
    fileReader.readAsDataURL(e.target.files[0]);
  };
  return (
    <>
      <Snackbar open={openSnackBar} headline="Problem reported Successfully" variant="info" message="" />
      <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
        <div className="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <DialogTitle>Report a Problem</DialogTitle>
          <IconButton onClick={onClose} style={{ marginRight: '1rem' }}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
        <DialogContent className={classes.container}>
          <form action="" style={{ display: 'flex', flexDirection: 'column' }} onSubmit={handleReportIssueSubmit}>
            <label htmlFor="">Issue</label>
            <InputBox handleChange={handleReportChange} name={'issue'} />
            <br />
            <label htmlFor="">Comment</label>
            <TextareaAutosize
              minRows="6"
              required
              onChange={handleReportChange}
              name={'comment'}
              style={{ padding: '1rem' }}
            />
            <br />
            <label htmlFor="">Include Screenshot.</label>
            <input
              accept="image/*"
              className={classes.input}
              id="icon-button-file"
              type="file"
              onChange={handleScreenChange}
            />
            {src && (
              <>
                <img src={src} alt="" style={{ width: '100px', height: '100px' }} />
              </>
            )}
            <label htmlFor="icon-button-file">
              <IconButton color="primary" aria-label="upload picture" component="span">
                <PhotoCamera />
              </IconButton>
            </label>
            <Divider />
            <DialogActions>
              {loading ? (
                <CircularProgress />
              ) : (
                <button
                  style={{
                    border: '0',
                    outline: '0',
                    backgroundColor: 'transparent',
                    color: '#cd8764',
                    fontSize: '1rem',
                    padding: '0',
                    margin: '0',
                  }}
                  type="submit"
                >
                  Send
                </button>
              )}
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReportProblemDialog;
