import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255, 255, 255, 0.01)',
  },
}));

interface IntroContainerProps {
  children: React.ReactNode;
}

const BackgroundWrapper = (props: IntroContainerProps) => {
  const classes = useStyles();

  return <div className={classes.background}>{props.children}</div>;
};

export default BackgroundWrapper;
