import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sessionGoalCardContainer: {
      width: '100%',
      height: '56px',
      backgroundColor: 'rgba(205, 135, 100, 0.06)',
      borderRadius: '8px',
      border: '1px solid #CD8764',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '.5rem',
      textAlign: 'center',
      padding: '5px',
    },
    sessionGoalText: {
      margin: '0',
      padding: '0',
      color: '#CD8764',
      fontWeight: 'bold',
    },
  })
);

const SessionGoal = (props: { text: string }) => {
  const classes = useStyles();
  return (
    <div className={classes.sessionGoalCardContainer}>
      <p className={classes.sessionGoalText}>{props.text}</p>
    </div>
  );
};

export default SessionGoal;
