import React from 'react';

export default function MicOffIcon() {
  return (
    <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.81156 1.33984C8.14384 1.33984 7.50348 1.60793 7.03134 2.08513C6.55919 2.56233 6.29395 3.20955 6.29395 3.88441V10.6699C6.29395 11.3448 6.55919 11.992 7.03134 12.4692C7.50348 12.9464 8.14384 13.2145 8.81156 13.2145C9.47927 13.2145 10.1196 12.9464 10.5918 12.4692C11.0639 11.992 11.3292 11.3448 11.3292 10.6699V3.88441C11.3292 3.20955 11.0639 2.56233 10.5918 2.08513C10.1196 1.60793 9.47927 1.33984 8.81156 1.33984V1.33984Z"
        stroke="#F8F8F8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.6859 8.97363V10.67C14.6859 12.2447 14.067 13.7549 12.9653 14.8683C11.8636 15.9818 10.3694 16.6073 8.81144 16.6073C7.25344 16.6073 5.75926 15.9818 4.65759 14.8683C3.55592 13.7549 2.93701 12.2447 2.93701 10.67V8.97363"
        stroke="#F8F8F8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        width="1.96865"
        height="23.6238"
        rx="0.984325"
        transform="matrix(0.703332 -0.710861 0.703332 0.710861 0 1.39941)"
        fill="#FC6868"
      />
      <rect
        width="1.5"
        height="23.6238"
        rx="0.75"
        transform="matrix(0.707107 -0.707107 0.699538 0.714596 0.0419922 2.98242)"
        fill="#F8F8F8"
      />
      <path
        d="M8.81104 16.6069V19.9997"
        stroke="#F8F8F8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.45508 20H12.1687"
        stroke="#F8F8F8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
