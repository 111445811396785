import React, { SetStateAction, useEffect, useState } from 'react';
import { makeStyles, Typography, Grid, Button, Theme, Hidden, colors, Checkbox } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import { Steps } from '../PreJoinScreens';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import axios from 'axios';
import Countdown from 'react-countdown';
import Snackbar from '../../Snackbar/Snackbar';
import { useHistory } from 'react-router-dom';
import CheckBox from '../../../custom/CheckBox/CheckBox';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    margin: '0 .5rem',
    color: '#fff',
    borderRadius: '8px',
    border: '1px solid #cd8764',
  },
  localPreviewContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  agreeText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
  joinBtn: {
    height: '44px',
    width: '137px',
    backgroundColor: '#cd8764',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#e8986f',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
      width: '100%',
    },
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  setStep: (step: Steps) => void;
  status: boolean;
  startTime?: string;
  setJoinDisableStatus: (joinDisableStatus: boolean) => void;
}

export default function DeviceSelectionScreen({
  name,
  roomName,
  setStep,
  status,
  startTime,
  setJoinDisableStatus,
}: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { getToken, isFetching } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const [roomNameErr, setroomNameErr] = useState(false);
  const [checkBoxStatus, setcheckBoxStatus] = useState(false);
  const history = useHistory();

  var timeStamp = Date.now() + 0;

  if (startTime) {
    const date = new Date(startTime);

    timeStamp = date.getTime();
  }

  const handleJoin = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_BASE_URL}/room`, {
        name: roomName,
      })
      .then(res => {
        console.log(res);
        getToken(name, roomName).then(({ token }) => {
          videoConnect(token);
          process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(token);
        });
      })
      .catch(Err => {
        if (Err.response.status == 406) {
          setroomNameErr(true);
          setTimeout(() => {
            setroomNameErr(false);
            history.push('/meet');
          }, 2000);
        } else {
          console.log('Error on creating room', Err.response.date);
        }
      });
  };

  if (isFetching || isConnecting) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
            Joining Meeting
          </Typography>
        </div>
      </Grid>
    );
  }

  return (
    <>
      {/* <Typography variant="h5" className={classes.gutterBottom}>
        Join {roomName}
      </Typography> */}
      <Snackbar headline="Room Name Already Exists." message="" variant="error" open={roomNameErr} />
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} sm={12} xs={12}>
          <Grid>
            {startTime && status && (
              <>
                {' '}
                <Typography variant="caption">Your meeting will start in : </Typography>
                <Countdown
                  date={timeStamp}
                  autoStart={true}
                  zeroPadTime={2}
                  zeroPadDays={0}
                  onComplete={() => setJoinDisableStatus(false)}
                />
              </>
            )}
          </Grid>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={name} />
          </div>
        </Grid>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            marginTop: '10px',
          }}
        >
          <CheckBox setcheckBoxStatus={setcheckBoxStatus} />
          <Typography className={classes.agreeText} variant="body1">
            By checking this box, you agree to your video and voice being recorded.{' '}
          </Typography>
        </div>
        <Grid item md={12} sm={12} xs={12}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            style={{ height: '100%', marginTop: '1rem' }}
          >
            <div className="">
              <SettingsMenu mobileButtonClass={classes.mobileButton} />
            </div>
            <div>
              <ToggleAudioButton className={classes.deviceButton} disabled={disableButtons} />
              <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} />
            </div>
            <div className="">
              <Button
                variant="contained"
                data-cy-join-now
                onClick={handleJoin}
                disabled={disableButtons || status || checkBoxStatus}
                className={classes.joinBtn}
              >
                Join Now
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
