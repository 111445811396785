import React from 'react';

export default function ScreenShareIcon() {
  return (
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 0H2C0.89 0 0 0.89 0 2V16C0 17.11 0.89 18 2 18H20C21.11 18 22 17.11 22 16V2C22 0.89 21.11 0 20 0ZM19 16.02H3C2.45 16.02 2 15.57 2 15.02V2.98C2 2.43 2.45 1.98 3 1.98H19C19.55 1.98 20 2.43 20 2.98V15.02C20 15.57 19.55 16.02 19 16.02ZM10 9H7L10.65 5.35C10.85 5.15 11.16 5.15 11.36 5.35L15 9H12V13H10V9Z"
        fill="#F8F8F8"
      />
    </svg>
  );
}
