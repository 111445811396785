import { useContext } from 'react';
import { SessionContext } from '../SessionProvider';

export default function useSessionContext() {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSessionContext Error');
  }
  return context;
}
