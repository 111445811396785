import React from 'react';

export default function ChatIcon() {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 15V32L16.6877 28.3562C17.2142 27.947 17.862 27.7248 18.5288 27.7248H29C30.6569 27.7248 32 26.3817 32 24.7248V15C32 13.3431 30.6569 12 29 12H15C13.3431 12 12 13.3431 12 15Z"
        stroke="white"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M18 18H26.5M18 22.5H26.5"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
