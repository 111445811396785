import React, { createContext, useState } from 'react';

type SessionContextType = {
  isSessionWindowOpen: boolean;
  setIsSessionWindowOpen: (isSessionWindowOpen: boolean) => void;
  sessionDetailsExists: boolean;
  setSessionDetailsExists: (sessionDetailsExists: boolean) => void;
  sessionGoalDetailsContext: any;
  setsessionGoalDetailsContext: any;
};

export const SessionContext = createContext<SessionContextType>(null!);

export const SessionProvider: React.FC = ({ children }) => {
  const [sessionDetailsExists, setSessionDetailsExists] = useState(false);
  const [isSessionWindowOpen, setIsSessionWindowOpen] = useState(false);
  const [sessionGoalDetailsContext, setsessionGoalDetailsContext] = useState(null);

  return (
    <SessionContext.Provider
      value={{
        isSessionWindowOpen,
        setIsSessionWindowOpen,
        sessionDetailsExists,
        setSessionDetailsExists,
        setsessionGoalDetailsContext,
        sessionGoalDetailsContext,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
