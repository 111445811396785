import { makeStyles, Theme } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import axios from 'axios';
import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import IntroContainer from '../../components/IntroContainer/IntroContainer';
import ChatIcon from '../../icons/ChatIcon';
import EmptyStartIcon from '../../icons/EmptyStartIcon';
import EndCallIcon from '../../icons/EndCallIcon';
import StarIcon from '../../icons/StarIcon';
import Navbar from '../Navbar/Navbar';
import queryString from 'query-string';

const useStyles = makeStyles((theme: Theme) => ({
  Wrapper: {
    width: '100%',
    height: '92vh',
    backgroundColor: '#FEFBF5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  Container: {
    width: '22rem',
    backgroundColor: '#FCF7F4',
    height: 'auto',
    border: '1px solid #F4E7D2',
    borderRadius: '8px',
    boxShadow: '0px 16px 44px 0px #F1E7E3',
    marginBottom: '3rem',
    padding: '2rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  EndText: {
    fontSize: '22px',
    color: '#000000',
    marginBottom: '2rem',
  },
  Qtext: {
    fontSize: '18px',
    color: '#000000',
  },
  RatingTextWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    padding: '10px',
    marginTop: '3px',
    color: '#8E8E8E',
  },
  ReturnToDashboard: {
    backgroundColor: '#CD8764',
    outline: '0',
    border: '0',
    width: '100%',
    padding: '1rem 0',
    color: '#fff',
    borderRadius: '8px',
    fontSize: '16px',
    '&:hover': { backgroundColor: '#db916b' },
  },
}));

const EndCallScreen = () => {
  const [value, setValue] = React.useState<number | null>(0);
  const { search } = useLocation();
  const { sess } = queryString.parse(search);
  const { URLRoomName } = useParams<any>();

  const [sessionId, setSessionId] = useState<string>((sess as string) || '');
  const [isMentor, setIsMentor] = useState(localStorage.getItem('mentor'));
  const history = useHistory();

  const handleSubmitRating = (e: any) => {
    e.preventDefault();
    if (value === null) {
      setValue(0);
    }

    axios
      .post(`${process.env.REACT_APP_SERVER_BASE_URL}/room/rating`, {
        rating: value,
        uniqueName: URLRoomName,
        sessionId: sessionId,
      })
      .then(() => {
        if (sessionId) {
          if (isMentor) {
            window.open(`${process.env.REACT_APP_MAPOUT_URL}/mentor-dashboard?em=${true}`, '_blank');
          } else {
            window.open(`${process.env.REACT_APP_MAPOUT_URL}/user-dashboard?em=${true}`, '_blank');
          }
        } else {
          history.push('/');
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const classes = useStyles();
  return (
    <>
      <Navbar />
      <div className={classes.Wrapper}>
        <h4 className={classes.EndText}>The Session has Ended</h4>
        <div className={classes.Container}>
          <h6>How was the audio and video?</h6>
          <Rating
            name="simple-controlled"
            value={value}
            size="large"
            icon={<StarIcon />}
            style={{ gap: '13px', marginTop: '.8rem' }}
            emptyIcon={<EmptyStartIcon />}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          />
          <div className={classes.RatingTextWrapper}>
            <p>Very Bad</p>
            <p>Very Good</p>
          </div>
          <button className={classes.ReturnToDashboard} onClick={handleSubmitRating}>
            {sessionId ? 'Return to Dashboard' : 'Return to Home'}
          </button>
        </div>
      </div>
    </>
  );
};

export default EndCallScreen;
