import React from 'react';

const MoreDotsIcon = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="16" r="2" fill="#fff" />
      <circle cx="22" cy="22" r="2" fill="#fff" />
      <circle cx="22" cy="28" r="2" fill="#fff" />
    </svg>
  );
};

export default MoreDotsIcon;
