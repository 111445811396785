import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button, IconButton } from '@material-ui/core';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import EndCallIcon from '../../../icons/EndCallIcon';

import axios from 'axios';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    quitConfirmBtn: {
      borderRadius: '8px',
      padding: '5px 20px',
      backgroundColor: '#cd8764',
      margin: '.5rem',
      border: '0px',
      outline: 'none',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#e8986f',
      },
    },
    quitCancelBtn: {
      padding: '5px 10px',
      borderRadius: '8px',
      margin: '.5rem',
      border: '0px',
      outline: 'none',
      color: '#cd8764',
    },
    button: {
      borderRadius: '8px',
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

export default function EndCallButton(props: { className?: string; sessionDetails?: any }) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const history = useHistory();

  const handleEndCall = (e: any) => {
    e.preventDefault();
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: classes.quitConfirmBtn,
        cancelButton: classes.quitCancelBtn,
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        showCancelButton: true,
        confirmButtonText: 'Quit',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
      })
      .then(result => {
        if (result.isConfirmed) {
          room!.disconnect();
          history.push(
            `/end/${room?.name}?sess=${props.sessionDetails?._id != undefined ? props.sessionDetails?._id : ''}`
          );
          // if (room?.participants.size == 0) {
          //   axios
          //     .post(`${process.env.REACT_APP_SERVER_BASE_URL}/room/complete`, {
          //       room_sid: room?.sid,
          //     })
          //     .then(res => {
          //       console.log(res);
          //     })
          //     .catch(Err => {
          //       console.log('Error on Disconnecting from room', Err);
          //     });
          // }
        }
      });
  };

  return (
    <IconButton onClick={handleEndCall} className={clsx(classes.button, props.className)} data-cy-disconnect>
      <EndCallIcon />
    </IconButton>
  );
}
