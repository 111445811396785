import { Checkbox } from '@material-ui/core';
import React, { useEffect } from 'react';
import CheckedCheckBox from '../../icons/CheckedCheckBox';
import UncheckedCheckBox from '../../icons/UncheckedCheckBox';

const CheckBox = (props: { setcheckBoxStatus: any }) => {
  const [checked, setChecked] = React.useState(false);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    props.setcheckBoxStatus(checked);
  };

  useEffect(() => {
    props.setcheckBoxStatus(!checked);
  });

  return (
    <Checkbox
      {...label}
      checked={checked}
      onChange={handleChange}
      icon={<UncheckedCheckBox />}
      checkedIcon={<CheckedCheckBox />}
    />
  );
};

export default CheckBox;
