import React, { useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#fff',
  },
  appBar: {
    backgroundColor: '#fff',
    boxShadow: 'none',
    color: '#111',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const AudioVideoTabs = ({ setSelected }: { setSelected: any }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState<string>('1');

  useEffect(() => {
    setSelected('1');
  }, []);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
    setSelected(newValue);
  };

  return (
    <div className={classes.root}>
      <TabContext value={value}>
        <AppBar position="static" className={classes.appBar}>
          <TabList onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Audio" value="1">
              hello
            </Tab>
            <Tab label="Video" value="2">
              heyy
            </Tab>
          </TabList>
        </AppBar>
      </TabContext>
    </div>
  );
};

export default AudioVideoTabs;
