import React, { useEffect, useState } from 'react';
import { styled, Theme } from '@material-ui/core/styles';

import MenuBar from './components/MenuBar/MenuBar';
import MobileTopMenuBar from './components/MobileTopMenuBar/MobileTopMenuBar';
import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import RecordingNotifications from './components/RecordingNotifications/RecordingNotifications';
import Room from './components/Room/Room';

import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import Navbar from './custom/Navbar/Navbar';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import useSessionContext from './custom/useSessionContext/useSessionContext';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  overflow: 'hidden',
  paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  background: 'black',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
  },
}));

export default function App() {
  const roomState = useRoomState();

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  const { search } = useLocation();
  const { sess } = queryString.parse(search);

  const [sessionId, setSessionId] = useState<string>((sess as string) || '');
  const [sessionDetails, setSessionDetails] = useState<any>({});
  const { setSessionDetailsExists, setsessionGoalDetailsContext } = useSessionContext();

  const getSessionGoalDetails = (sessId: string) => {
    axios
      .get(`${process.env.REACT_APP_MAPOUT_BACKEND_URL}/sessions/goal/${sessId}`)
      .then(res => {
        setsessionGoalDetailsContext(res.data[0]);
      })
      .catch(err => {
        console.log('An error on fetching Session Goal details : ', err);
      });
  };

  const getSessionDetails = (sessId: string) => {
    axios
      .get(`${process.env.REACT_APP_MAPOUT_BACKEND_URL}/sessions?_id=${sessId}`)
      .then(res => {
        setSessionDetails(res.data[0]);
        setSessionDetailsExists(true);
        getSessionGoalDetails(sessId);
      })
      .catch(err => {
        console.log('An error on fetching Session details : ', err);
      });
  };

  useEffect(() => {
    if (sessionId) {
      getSessionDetails(sessionId);
    }
  }, [window.location]);

  return (
    <Container style={{ height }}>
      {roomState === 'disconnected' ? (
        <PreJoinScreens />
      ) : (
        <Main>
          <ReconnectingNotification />
          <RecordingNotifications />
          <MobileTopMenuBar sessionDetails={sessionDetails} />
          <Room sessionDetails={sessionDetails} />
          <MenuBar sessionDetails={sessionDetails} />
        </Main>
      )}
    </Container>
  );
}
