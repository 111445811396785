import React, { useState, useRef } from 'react';
import AboutDialog from '../../AboutDialog/AboutDialog';
import BackgroundIcon from '../../../icons/BackgroundIcon';
import DeviceSelectionDialog from '../../DeviceSelectionDialog/DeviceSelectionDialog';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIconOutlined from '../../../icons/InfoIconOutlined';
import MoreIcon from '@material-ui/icons/MoreVert';
import StartRecordingIcon from '../../../icons/StartRecordingIcon';
import StopRecordingIcon from '../../../icons/StopRecordingIcon';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '../../../icons/SettingsIcon';
import { Icon } from '@iconify/react';
import {
  Button,
  styled,
  Theme,
  useMediaQuery,
  Menu as MenuContainer,
  MenuItem,
  Typography,
  IconButton,
} from '@material-ui/core';
import { isSupported } from '@twilio/video-processors';

import { useAppState } from '../../../state';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useIsRecording from '../../../hooks/useIsRecording/useIsRecording';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import FlipCameraIcon from '../../../icons/FlipCameraIcon';
import useFlipCameraToggle from '../../../hooks/useFlipCameraToggle/useFlipCameraToggle';
import { VideoRoomMonitor } from '@twilio/video-room-monitor';
import MoreDotsIcon from '../../../icons/MoreDotsIcon';
import EnterFullScreenIcon from '../../../icons/EnterFullScreenIcon';
import ReportProblemIcon from '../../../icons/ReportProblemIcon';
import ReportAbuse from '../../../icons/ReportAbuse';
import OnCallSettingsIcon from '../../../icons/OnCallSettingsIcon';
import ReportProblemDialog from '../../../custom/ReportProblem/ReportProblemDialog';
import ReportAbuseDialog from '../../../custom/ReportAbuse/ReportAbuseDialog';
import useSessionContext from '../../../custom/useSessionContext/useSessionContext';

export const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '1.5em',
  marginRight: '0.3em',
});

export default function Menu(props: { buttonClassName?: string; sessionDetails?: any }) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [aboutOpen, setAboutOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [reportProblemOpen, setReportProblemOpen] = useState(false);
  const [reportAbuseOpen, setReportAbuseOpen] = useState(false);

  const { isFetching, updateRecordingRules, roomType } = useAppState();
  const { setIsChatWindowOpen } = useChatContext();
  const isRecording = useIsRecording();
  const { room, setIsBackgroundSelectionOpen } = useVideoContext();
  const { setIsSessionWindowOpen } = useSessionContext();

  const anchorRef = useRef<HTMLButtonElement>(null);
  const { flipCameraDisabled, toggleFacingMode, flipCameraSupported } = useFlipCameraToggle();

  return (
    <>
      <IconButton
        onClick={() => setMenuOpen(isOpen => !isOpen)}
        ref={anchorRef}
        className={props.buttonClassName}
        data-cy-more-button
        style={{ padding: '0' }}
      >
        {isMobile ? (
          <MoreIcon />
        ) : (
          <>
            <div
              style={{
                height: '44px',
                width: '44px',
                backgroundColor: '#404040',
                borderRadius: '8px',
                margin: '0',
                padding: '0',
              }}
            >
              <MoreDotsIcon />
            </div>
          </>
        )}
      </IconButton>

      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen(isOpen => !isOpen)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: isMobile ? -55 : 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography variant="h6" style={{ fontSize: '16px', marginLeft: '1rem' }}>
          Other Options
        </Typography>

        {isSupported && (
          <MenuItem
            onClick={() => {
              setIsBackgroundSelectionOpen(true);
              setIsChatWindowOpen(false);
              setIsSessionWindowOpen(false);
              setMenuOpen(false);
            }}
          >
            <IconContainer>
              <BackgroundIcon />
            </IconContainer>
            <Typography variant="body1" style={{ marginLeft: '.5rem' }}>
              Backgrounds
            </Typography>
          </MenuItem>
        )}

        {/* {roomType !== 'peer-to-peer' && roomType !== 'go' && (
          <MenuItem
            disabled={isFetching}
            onClick={() => {
              setMenuOpen(false);
              if (isRecording) {
                updateRecordingRules(room!.sid, [{ type: 'exclude', all: true }]);
              } else {
                updateRecordingRules(room!.sid, [{ type: 'include', all: true }]);
              }
            }}
            data-cy-recording-button
          >
            <IconContainer>{isRecording ? <StopRecordingIcon /> : <StartRecordingIcon />}</IconContainer>
            <Typography variant="body1" style={{ marginLeft: '.5rem' }}>
              {isRecording ? 'Stop' : 'Start'} Recording
            </Typography>
          </MenuItem>
        )} */}

        {/* <MenuItem onClick={() => setSettingsOpen(true)}>
          <IconContainer>
            <EnterFullScreenIcon />
          </IconContainer>
          <Typography variant="body1" style={{ marginLeft: '.5rem' }}>
            Enter Full Screen
          </Typography>
        </MenuItem> */}

        <MenuItem onClick={() => setReportProblemOpen(true)}>
          <IconContainer>
            <ReportProblemIcon />
          </IconContainer>
          <Typography variant="body1" style={{ marginLeft: '.5rem' }}>
            Report Problem
          </Typography>
        </MenuItem>

        <MenuItem onClick={() => setReportAbuseOpen(true)}>
          <IconContainer>
            <ReportAbuse />
          </IconContainer>
          <Typography variant="body1" style={{ marginLeft: '.5rem' }}>
            Report abuse
          </Typography>
        </MenuItem>

        <MenuItem onClick={() => setSettingsOpen(true)}>
          <IconContainer>
            <OnCallSettingsIcon />
          </IconContainer>
          <Typography variant="body1" style={{ marginLeft: '.5rem' }}>
            Settings
          </Typography>
        </MenuItem>

        {flipCameraSupported && (
          <MenuItem disabled={flipCameraDisabled} onClick={toggleFacingMode}>
            <IconContainer>
              <FlipCameraIcon />
            </IconContainer>
            <Typography variant="body1" style={{ marginLeft: '.5rem' }}>
              Flip Camera
            </Typography>
          </MenuItem>
        )}

        {/* <MenuItem
          onClick={() => {
            VideoRoomMonitor.toggleMonitor();
            setMenuOpen(false);
          }}
        >
          <IconContainer>
            <SearchIcon style={{ fill: '#707578', width: '0.9em' }} />
          </IconContainer>
          <Typography variant="body1">Room Monitor</Typography>
        </MenuItem> */}

        {/* <MenuItem onClick={() => setAboutOpen(true)}>
          <IconContainer>
            <InfoIconOutlined />
          </IconContainer>
          <Typography variant="body1">About</Typography>
        </MenuItem> */}
      </MenuContainer>
      <AboutDialog
        open={aboutOpen}
        onClose={() => {
          setAboutOpen(false);
          setMenuOpen(false);
        }}
      />
      <DeviceSelectionDialog
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
      <ReportProblemDialog
        open={reportProblemOpen}
        sessionDetails={props.sessionDetails}
        onClose={() => {
          setReportProblemOpen(false);
          setMenuOpen(false);
        }}
      />
      <ReportAbuseDialog
        open={reportAbuseOpen}
        sessionDetails={props.sessionDetails}
        onClose={() => {
          setReportAbuseOpen(false);
          setMenuOpen(false);
        }}
      />
    </>
  );
}
