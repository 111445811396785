import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
  })
);

const InputBox = (props: { handleChange: any; name: string }) => {
  const classes = useStyles();
  return (
    <TextField
      id="outlined-basic"
      variant="outlined"
      style={{ marginTop: '10px' }}
      required
      onChange={props.handleChange}
      name={props.name}
    />
  );
};

export default InputBox;
