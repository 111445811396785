import React from 'react';
import { Container, makeStyles, Theme, Typography } from '@material-ui/core';
import Swoosh from './swoosh';
import VideoLogo from './VideoLogo';
import TwilioLogo from './TwilioLogo';
import { useAppState } from '../../state';
import UserMenu from './UserMenu/UserMenu';
import { useLocation } from 'react-router-dom';
import Navbar from '../../custom/Navbar/Navbar';
import BackgroundWrapper from '../../custom/BackgroundWrapper/BackgroundWrapper';

const useStyles = makeStyles((theme: Theme) => ({
  // background: {
  //   display: 'flex',
  //   height: '100%',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   background: '#FEFBF5',
  // },
  container: {
    flex: '1',
    display: 'flex',
    alignItems: 'center',
  },
  innerContainer: {
    display: 'flex',
    width: '1200px',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      height: 'auto',
      width: '888px',
      margin: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: 'auto',
      width: '640px',
      margin: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      height: 'auto',
      width: 'calc(100% - 40px)',
      margin: 'auto',
      maxWidth: '400px',
    },
  },
  // swooshContainer: {
  //   position: 'relative',
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   backgroundImage: Swoosh,
  //   backgroundSize: 'cover',
  //   width: '296px',
  //   [theme.breakpoints.down('sm')]: {
  //     width: '100%',
  //     height: '100px',
  //     backgroundPositionY: '140px',
  //   },
  // },
  // logoContainer: {
  //   position: 'absolute',
  //   width: '210px',
  //   textAlign: 'center',
  //   [theme.breakpoints.down('sm')]: {
  //     display: 'flex',
  //     alignItems: 'center',
  //     width: '90%',
  //     textAlign: 'initial',
  //     '& svg': {
  //       height: '64px',
  //     },
  //   },
  // },
  // twilioLogo: {
  //   position: 'absolute',
  //   top: 0,
  //   left: 0,
  //   margin: '20px',
  // },
  content: {
    background: 'FEFBF5',
    width: '100%',
    padding: '4em',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '2em',
    },
  },
  title: {
    color: 'white',
    margin: '1em 0 0',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      fontSize: '1.1rem',
    },
  },
}));

interface IntroContainerProps {
  children: React.ReactNode;
}

const IntroContainer = (props: IntroContainerProps) => {
  const classes = useStyles();
  const { user } = useAppState();
  const location = useLocation();

  return (
    <div>
      <BackgroundWrapper>
        <Navbar />
        {user && location.pathname !== '/login' && <UserMenu />}
        <div className={classes.container}>
          <div className={classes.innerContainer}>
            <div className={classes.content}>{props.children}</div>
          </div>
        </div>
      </BackgroundWrapper>
    </div>
  );
};

export default IntroContainer;
