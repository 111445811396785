import React, { useEffect, useState } from 'react';

import {
  DialogContent,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  Button,
  Theme,
  DialogTitle,
  TextField,
  TextareaAutosize,
  IconButton,
  Select,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AudioVideoTabs from '../AudioVideoTabs/AudioVideoTabs';
import InputBox from '../Input/InputBox';
import { PhotoCamera } from '@material-ui/icons';
import ImageInput from '../ImageInput/ImageInput';
import CloseIcon from '../../icons/CloseIcon';
import axios from 'axios';
import { UPLOAD_SERVER } from '../../data-service/config';
import Snackbar from '../../components/Snackbar/Snackbar';
import { ObjectID } from 'bson';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '600px',
    minHeight: '350px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 32px)',
    },
    '& .inputSelect': {
      width: 'calc(100% - 35px)',
    },
  },
  button: {
    float: 'right',
  },
  paper: {
    [theme.breakpoints.down('xs')]: {
      margin: '16px',
    },
  },
  headline: {
    marginBottom: '1.3em',
    fontSize: '1.1rem',
  },
  listSection: {
    margin: '2em 0 0.8em',
    '&:first-child': {
      margin: '1em 0 2em 0',
    },
  },
  joinBtn: {
    height: '44px',
    width: '137px',
    backgroundColor: '#cd8764',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#e8986f',
    },
  },
  input: {
    display: 'none',
  },
}));

const ReportAbuseDialog = ({
  open,
  onClose,
  sessionDetails,
}: {
  open: boolean;
  onClose: () => void;
  sessionDetails: any;
}) => {
  const classes = useStyles();

  const [isMentor, setIsMentor] = useState(localStorage.getItem('mentor'));
  const [issue, setIssue] = React.useState('');
  const [comment, setComment] = React.useState('');
  const [abusers, setAbusers] = React.useState<any>([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setIssue(event.target.value as string);
  };

  const handleAbuseChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAbusers(event.target.value as string);
  };

  const handleReportIssueSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    const id = new ObjectID();
    const mentId = new ObjectID();
    const abuserArray = abusers.split(',');
    const abuseData = {
      meetingId: sessionDetails?._id ? sessionDetails?._id : id.toString(),
      abuseType: issue,
      reporterEmail: sessionDetails._id
        ? isMentor
          ? sessionDetails?.attendees?.mentorEmail
          : sessionDetails?.attendees?.candidateEmail
        : 'personal@mapout.com',
      mentorId: sessionDetails?._id ? sessionDetails?.mentorId : mentId.toString(),
      abusersNames: abuserArray,
      comments: comment,
      start: sessionDetails?._id ? sessionDetails?.start?.dateTime : 'no',
      end: sessionDetails?._id ? sessionDetails?.end?.dateTime : 'no',
    };

    axios
      .post(`${process.env.REACT_APP_MAPOUT_BACKEND_URL}/sessions/reportabuse`, abuseData)
      .then(res => {
        setOpenSnackBar(true);
        onClose();
        setLoading(false);
        setTimeout(() => setOpenSnackBar(false), 3000);
      })
      .catch(err => {
        setLoading(false);
        console.log('Error on reporting issue', err);
      });
  };

  return (
    <>
      <Snackbar open={openSnackBar} headline="Abuse reported Successfully" variant="info" message="" />
      <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
        <div className="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <DialogTitle>Report an Abuse</DialogTitle>
          <IconButton onClick={onClose} style={{ marginRight: '1rem' }}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
        <DialogContent className={classes.container}>
          <form action="" style={{ display: 'flex', flexDirection: 'column' }} onSubmit={handleReportIssueSubmit}>
            <label htmlFor="">Issue</label>
            <Select
              variant="outlined"
              style={{ marginTop: '10px' }}
              required
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={issue}
              onChange={handleChange}
            >
              <MenuItem value={'Spam or unwanted content'}>Spam or unwanted content</MenuItem>
              <MenuItem value={'Fraud, phishing and other deceptive practices'}>
                Fraud, phishing and other deceptive practices
              </MenuItem>
              <MenuItem value={'Malware (distributed via link in the Meet chat window)'}>
                Malware (distributed via link in the Meet chat window)
              </MenuItem>
              <MenuItem value={'Harassment and hateful content'}>Harassment and hateful content</MenuItem>
              <MenuItem value={'Other'}>Other</MenuItem>
            </Select>
            <br />
            <label htmlFor="">Abuser Name</label>
            <InputBox handleChange={handleAbuseChange} name={'abuse'} />
            <label htmlFor="">Comment</label>
            <TextareaAutosize
              minRows="6"
              required
              onChange={e => setComment(e.target.value)}
              name={'comment'}
              style={{ padding: '1rem' }}
            />

            <Divider />
            <DialogActions>
              {loading ? (
                <CircularProgress />
              ) : (
                <button
                  style={{
                    border: '0',
                    outline: '0',
                    backgroundColor: 'transparent',
                    color: '#cd8764',
                    fontSize: '1rem',
                    padding: '0',
                    margin: '0',
                  }}
                  type="submit"
                >
                  Send
                </button>
              )}
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReportAbuseDialog;
