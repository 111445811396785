import React from 'react';

const OnCallSettingsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width="2em"
      height="2em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M13.82 22h-3.64a1 1 0 0 1-.977-.786l-.407-1.884a8.002 8.002 0 0 1-1.535-.887l-1.837.585a1 1 0 0 1-1.17-.453L2.43 15.424a1.006 1.006 0 0 1 .193-1.239l1.425-1.3a8.1 8.1 0 0 1 0-1.772L2.623 9.816a1.006 1.006 0 0 1-.193-1.24l1.82-3.153a1 1 0 0 1 1.17-.453l1.837.585c.244-.18.498-.348.76-.5c.253-.142.513-.271.779-.386l.408-1.882A1 1 0 0 1 10.18 2h3.64a1 1 0 0 1 .976.787l.412 1.883a7.993 7.993 0 0 1 1.534.887l1.838-.585a1 1 0 0 1 1.169.453l1.82 3.153c.232.407.152.922-.193 1.239l-1.425 1.3a8.1 8.1 0 0 1 0 1.772l1.425 1.3c.345.318.425.832.193 1.239l-1.82 3.153a1 1 0 0 1-1.17.453l-1.837-.585a7.98 7.98 0 0 1-1.534.886l-.412 1.879a1 1 0 0 1-.976.786Zm-6.2-5.771l.82.6c.185.136.377.261.577.375c.188.109.38.207.579.296l.933.409l.457 2.091h2.03l.457-2.092l.933-.409c.407-.18.794-.403 1.153-.666l.82-.6l2.042.65l1.015-1.758l-1.583-1.443l.112-1.012c.05-.443.05-.89 0-1.332l-.112-1.012l1.584-1.446l-1.016-1.759l-2.041.65l-.821-.6a6.227 6.227 0 0 0-1.153-.671l-.933-.409L13.016 4h-2.03l-.46 2.092l-.93.408a6.01 6.01 0 0 0-1.153.666l-.821.6l-2.04-.65L4.565 8.88l1.583 1.441l-.112 1.013c-.05.443-.05.89 0 1.332l.112 1.012l-1.583 1.443l1.015 1.758l2.04-.65ZM11.996 16a4 4 0 1 1 0-8a4 4 0 0 1 0 8Zm0-6a2 2 0 1 0 2 2.09v.4V12a2 2 0 0 0-2-2Z"
      />
    </svg>
  );
};

export default OnCallSettingsIcon;
