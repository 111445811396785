import { IconButton, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SessioinInfoIcon from '../../icons/SessioinInfoIcon';
import clsx from 'clsx';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSessionContext from '../useSessionContext/useSessionContext';

export const ANIMATION_DURATION = 700;

const useStyles = makeStyles({
  iconContainer: {
    position: 'relative',
    display: 'flex',
  },
  circle: {
    width: '10px',
    height: '10px',
    backgroundColor: '#5BB75B',
    borderRadius: '50%',
    position: 'absolute',
    top: '-3px',
    left: '13px',
    opacity: 0,
    transition: `opacity ${ANIMATION_DURATION * 0.5}ms ease-in`,
  },
  hasUnreadMessages: {
    opacity: 1,
  },
  ring: {
    border: '3px solid #5BB75B',
    borderRadius: '30px',
    height: '14px',
    width: '14px',
    position: 'absolute',
    left: '11px',
    top: '-5px',
    opacity: 0,
  },
  animateRing: {
    animation: `$expand ${ANIMATION_DURATION}ms ease-out`,
    animationIterationCount: 1,
  },
  '@keyframes expand': {
    '0%': {
      transform: 'scale(0.1, 0.1)',
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      transform: 'scale(1.4, 1.4)',
      opacity: 0,
    },
  },
});

const ToggleSessionButton = () => {
  const classes = useStyles();
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const { setIsChatWindowOpen } = useChatContext();
  const { setIsBackgroundSelectionOpen } = useVideoContext();
  const { isSessionWindowOpen, setIsSessionWindowOpen } = useSessionContext();

  useEffect(() => {
    if (shouldAnimate) {
      setTimeout(() => setShouldAnimate(false), ANIMATION_DURATION);
    }
  }, [shouldAnimate]);

  const toggleSessionWindow = () => {
    setIsSessionWindowOpen(!isSessionWindowOpen);
    setIsChatWindowOpen(false);
    setIsBackgroundSelectionOpen(false);
  };

  return (
    <IconButton
      onClick={toggleSessionWindow}
      style={{
        height: '44px',
        width: '44px',
        backgroundColor: '#404040',
        borderRadius: '8px',
        padding: '0',
      }}
    >
      <SessioinInfoIcon />
      <div className={clsx(classes.ring, { [classes.animateRing]: shouldAnimate })} />
    </IconButton>
  );
};

export default ToggleSessionButton;
