import React from 'react';

export default function VideoOnIcon() {
  return (
    <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.75" y="0.75" width="13.5" height="10.5" rx="2.25" stroke="white" stroke-width="1.5" />
      <path
        d="M14.5932 5.13328C14.1275 5.61726 14.1275 6.38274 14.5932 6.86672L17.1493 9.52311L17.6898 9.00308L17.1493 9.52311C17.9296 10.3339 19.3 9.78165 19.3 8.65639L19.3 3.34361C19.3 2.21835 17.9296 1.66606 17.1493 2.47689L14.5932 5.13328Z"
        stroke="white"
        stroke-width="1.5"
      />
    </svg>
  );
}
