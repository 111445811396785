import React from 'react';

const EmptyStartIcon = () => {
  return (
    <svg width="39" height="37" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3274 2.0574C15.6026 1.49997 16.3974 1.49997 16.6726 2.0574L20.2685 9.34361C20.5963 10.0077 21.2298 10.468 21.9626 10.5745L30.0034 11.7428C30.6186 11.8322 30.8642 12.5882 30.4191 13.0221L24.6007 18.6936C24.0704 19.2105 23.8284 19.9553 23.9536 20.6852L25.3271 28.6935C25.4322 29.3062 24.7892 29.7734 24.2389 29.4841L17.047 25.7031C16.3915 25.3585 15.6085 25.3585 14.953 25.7031L7.76107 29.4841C7.21085 29.7734 6.56777 29.3062 6.67286 28.6935L8.04639 20.6852C8.17157 19.9553 7.92959 19.2105 7.3993 18.6936L1.58092 13.0221C1.13578 12.5882 1.38141 11.8322 1.99658 11.7428L10.0374 10.5745C10.7702 10.468 11.4038 10.0077 11.7315 9.34361L15.3274 2.0574Z"
        stroke="#8E8E8E"
        stroke-width="1.5"
      />
    </svg>
  );
};

export default EmptyStartIcon;
