import React from 'react';

const SessioinInfoIcon = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="22" r="10" fill="#404040" stroke="white" stroke-width="2" />
      <circle cx="22" cy="17" r="1" fill="white" />
      <rect x="21" y="19" width="2" height="8" rx="1" fill="white" />
    </svg>
  );
};

export default SessioinInfoIcon;
