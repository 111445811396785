import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import useSessionContext from '../../useSessionContext/useSessionContext';
import SessionGoal from './SessionGoal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sessionWindowDetailsContainer: {
      padding: '1rem',
    },
    sessionGoalHeading: {
      margin: '1rem 0 0 0 ',
      color: 'rgba(64, 64, 64, 1)',
    },
    sessionGoalQuestions: {
      margin: '2rem 0 0 0 ',
      color: '#8E8E8E',
    },
    sessionGoalAnswer: {
      margin: '.5rem 0 0 0 ',
      color: '#404040',
    },
  })
);

const SessionGoalDetails = () => {
  const classess = useStyles();

  const { sessionGoalDetailsContext } = useSessionContext();
  return (
    <div className={classess.sessionWindowDetailsContainer}>
      <h5 className={classess.sessionGoalHeading}>Session Goal</h5>
      <p className={classess.sessionGoalQuestions}>What best describes you?</p>
      <h6 className={classess.sessionGoalAnswer}>{sessionGoalDetailsContext?.title}</h6>
      <p className={classess.sessionGoalQuestions}>What would you like to discuss with your mentor in the session?</p>
      {sessionGoalDetailsContext?.session_goals.map((goal: string) => (
        <SessionGoal text={goal} />
      ))}
      <p className={classess.sessionGoalQuestions}>Brief description</p>
      <h6 className={classess.sessionGoalAnswer}>{sessionGoalDetailsContext?.other_info}</h6>
    </div>
  );
};

export default SessionGoalDetails;
