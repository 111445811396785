import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { CDN } from '../../data-service/config';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    width: '100%',
    height: '76px',
    padding: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '90%',
    margin: 'auto',
  },
  logo: {
    height: '47px',
    marginRight: '20px',
  },
  wrapper: {
    width: '100%',
    backgroundColor: '#fff',
  },
}));

const Navbar = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <a href="">
          <img src={`${CDN}/assets/img/mapout-text-logo.svg`} alt="" className={classes.logo} />
        </a>
      </div>
    </div>
  );
};

export default Navbar;
