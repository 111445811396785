import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { useAppState } from '../../state';
import { useParams, useLocation } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import queryString from 'query-string';
import axios from 'axios';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

export default function PreJoinScreens() {
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName, meetRoomName } = useParams<any>();
  const [step, setStep] = useState(Steps.roomNameStep);
  const { search } = useLocation();

  const { uname, sess, m } = queryString.parse(search);

  const [name, setName] = useState<string>((uname as string) || '');
  const [roomName, setRoomName] = useState<string>(URLRoomName || '');
  const [sessionId, setSessionId] = useState<string>((sess as string) || '');

  const [sessionDetails, setSessionDetails] = useState<any>(null);

  const [disableStatus, setdisableStatus] = useState<boolean>(false);
  const [joindisableStatus, setJoinDisableStatus] = useState<boolean>(false);

  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
      setName(uname as string);
      if (roomName) setdisableStatus(true);
      if (name && roomName) {
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [user, URLRoomName]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const getSessionDetails = (sessId: string) => {
    axios
      .get(`${process.env.REACT_APP_MAPOUT_BACKEND_URL}/sessions?_id=${sessId}`)
      .then(res => {
        setSessionDetails(res.data[0]);
        const startTime = new Date(res.data[0].start.dateTime).getTime();
        const currentTime = new Date().getTime();
        if (startTime > currentTime) {
          setJoinDisableStatus(true);
        }
      })
      .catch(err => {
        console.log('An error on fetching Session details : ', err);
      });
  };

  useEffect(() => {
    if (sessionId) {
      getSessionDetails(sessionId);
      if (m) {
        localStorage.setItem('mentor', 'true');
      }
    }
  }, []);

  if (!window.location.origin.includes('twil.io')) {
    window.history.replaceState(null, '', window.encodeURI(`/meet/${roomName}?sess=${sessionId}`));
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    if (!window.location.origin.includes('twil.io')) {
      window.history.replaceState(null, '', window.encodeURI(`/meet/${roomName}`));
    }
    setStep(Steps.deviceSelectionStep);
  };

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomNameStep && (
        <RoomNameScreen
          status={disableStatus}
          name={name}
          roomName={roomName}
          setName={setName}
          setRoomName={setRoomName}
          handleSubmit={handleSubmit}
        />
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen
          name={name}
          roomName={roomName}
          setStep={setStep}
          status={joindisableStatus}
          setJoinDisableStatus={setJoinDisableStatus}
          startTime={sessionDetails && sessionDetails.start.dateTime}
        />
      )}
    </IntroContainer>
  );
}
