import React from 'react';

const EndCallIcon = () => {
  return (
    <svg width="20" height="8" viewBox="0 0 20 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.7557 6.44738L19.9529 5.41306C20.0455 4.8975 20.0011 4.36783 19.8238 3.87314C19.6465 3.37844 19.342 2.93472 18.9385 2.58308C16.9783 0.854643 13.9165 -0.00546102 9.7518 2.60876e-05C5.59422 0.0055132 2.62533 0.872477 0.845143 2.59954C0.0907766 3.3307 -0.183538 4.41578 0.123637 5.44598L0.432242 6.4803C0.720844 7.44603 1.69523 8.09214 2.70963 7.98926L4.74413 7.78486C5.16705 7.74491 5.56439 7.57196 5.87442 7.29289C6.18445 7.01383 6.38981 6.64426 6.4586 6.24161L6.85007 4.04128C7.83359 3.59936 8.90196 3.35833 9.98754 3.33344C11.1119 3.30052 12.1421 3.47474 13.0764 3.85746L13.7079 6.20869C13.9351 7.05508 14.6938 7.68747 15.5867 7.77252L17.6326 7.97005C18.1217 8.02297 18.6133 7.89495 19.0075 7.61206C19.4016 7.32917 19.6687 6.91268 19.7543 6.44738H19.7557Z"
        fill="white"
      />
    </svg>
  );
};

export default EndCallIcon;
