import React, { useState } from 'react';

import AudioInputList from './AudioInputList/AudioInputList';
import AudioOutputList from './AudioOutputList/AudioOutputList';
import {
  DialogContent,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  Button,
  Theme,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VideoInputList from './VideoInputList/VideoInputList';
import AudioVideoTabs from '../../custom/AudioVideoTabs/AudioVideoTabs';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '600px',
    minHeight: '350px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 32px)',
    },
    '& .inputSelect': {
      width: 'calc(100% - 35px)',
    },
  },
  button: {
    float: 'right',
  },
  paper: {
    [theme.breakpoints.down('xs')]: {
      margin: '16px',
    },
  },
  headline: {
    marginBottom: '1.3em',
    fontSize: '1.1rem',
  },
  listSection: {
    margin: '2em 0 0.8em',
    '&:first-child': {
      margin: '1em 0 2em 0',
    },
  },
  joinBtn: {
    height: '44px',
    width: '137px',
    backgroundColor: '#cd8764',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#e8986f',
    },
  },
}));

export default function DeviceSelectionDialog({ open, onClose }: { open: boolean; onClose: () => void }) {
  const classes = useStyles();
  const [selected, setSelected] = useState<any>('1');

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      {/* <DialogTitle>Audio and Video Settings</DialogTitle> */}
      <AudioVideoTabs setSelected={setSelected} />
      <Divider />
      <DialogContent className={classes.container}>
        {selected !== '1' ? (
          <>
            <div className={classes.listSection}>
              <Typography variant="h6" className={classes.headline}>
                Video
              </Typography>
              <VideoInputList />
            </div>
          </>
        ) : (
          <>
            <div className={classes.listSection}>
              <Typography variant="h6" className={classes.headline}>
                Audio
              </Typography>
              <AudioInputList />
            </div>
            <div className={classes.listSection}>
              <AudioOutputList />
            </div>
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" className={classes.joinBtn} onClick={onClose}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
