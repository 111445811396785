import React from 'react';

export default function VideoOffIcon() {
  return (
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.75" y="6.75" width="13.5" height="10.5" rx="2.25" stroke="white" stroke-width="1.5" />
      <path
        d="M14.5932 11.1333C14.1275 11.6173 14.1275 12.3827 14.5932 12.8667L17.1493 15.5231L17.6898 15.0031L17.1493 15.5231C17.9296 16.3339 19.3 15.7817 19.3 14.6564L19.3 9.34361C19.3 8.21835 17.9296 7.66606 17.1493 8.47689L14.5932 11.1333Z"
        stroke="white"
        stroke-width="1.5"
      />
      <rect x="3" y="2.06055" width="2" height="24" rx="1" transform="rotate(-45 3 2.06055)" fill="#FC6868" />
      <rect x="2" y="3.06055" width="1.5" height="24" rx="0.75" transform="rotate(-45 2 3.06055)" fill="#F8F8F8" />
    </svg>
  );
}
