import React from 'react';

const CameraOffIcon = () => {
  return (
    <div>
      <svg width="266" height="266" viewBox="0 0 266 266" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M101.556 124.424C107.871 124.424 112.99 119.305 112.99 112.991C112.99 106.676 107.871 101.557 101.556 101.557C95.2415 101.557 90.1226 106.676 90.1226 112.991C90.1226 119.305 95.2415 124.424 101.556 124.424Z"
          fill="#CD8764"
          fill-opacity="0.06"
        />
        <path
          d="M101.941 132.999C112.662 132.999 121.353 124.308 121.353 113.588C121.353 102.867 112.662 94.1758 101.941 94.1758C91.2202 94.1758 82.5293 102.867 82.5293 113.588C82.5293 124.308 91.2202 132.999 101.941 132.999Z"
          stroke="#CD8764"
          stroke-opacity="0.06"
          stroke-width="2"
        />
        <path
          d="M127.176 171.824C162.555 171.824 191.235 144.013 191.235 109.707C191.235 75.3999 162.555 47.5889 127.176 47.5889C91.7973 47.5889 63.1172 75.3999 63.1172 109.707C63.1172 144.013 91.7973 171.824 127.176 171.824Z"
          stroke="#CD8764"
          stroke-opacity="0.06"
          stroke-width="2"
        />
        <path
          d="M133 265C205.902 265 265 205.902 265 133C265 60.0984 205.902 1 133 1C60.0984 1 1 60.0984 1 133C1 205.902 60.0984 265 133 265Z"
          stroke="#CD8764"
          stroke-opacity="0.06"
          stroke-width="2"
        />
        <path
          d="M115.53 237.824C172.351 237.824 218.413 190.893 218.413 133C218.413 75.1079 172.351 28.1768 115.53 28.1768C58.7099 28.1768 12.6479 75.1079 12.6479 133C12.6479 190.893 58.7099 237.824 115.53 237.824Z"
          stroke="#CD8764"
          stroke-opacity="0.06"
          stroke-width="2"
        />
      </svg>
    </div>
  );
};

export default CameraOffIcon;
